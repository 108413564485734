.footer {
  background-color: #f8f8f8;
  padding: 20px;
  text-align: left;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #B3907A;
}

.left p,
.right p {
  margin: 5px 0;
  color: #333;
  font-size: 13px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

@media (max-width: 768px) {
  .footer {
    padding: 15px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .left,
  .right {
    text-align: center;
    /* 가운데 정렬 */
    width: 100%;
  }

  .left p,
  .right p {
    font-size: 12px;
    margin: 3px 0;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 10px;
  }

  .left p,
  .right p {
    font-size: 11px;
    margin: 2px 0;
  }
}