.Maindes01 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.Maindes01 p {
  font-size: 1.15em;
  text-align: center;
  margin: 20px 0;
  padding: 10px;
}

.images {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.image {
  flex: 1 1 auto;
  min-width: 0;
  height: auto;
  object-fit: cover;

  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 0;
}


.image:nth-child(1) {
  margin-top: 0;
}

.image:nth-child(2) {
  margin-top: 2%;
}

.image:nth-child(3) {
  margin-top: 4%;
}

.image:nth-child(4) {
  margin-top: 6%;
}

.image:nth-child(5) {
  margin-top: 8%;
}

.image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}


@media (max-width: 1200px) {
  .image {
    flex: 1 1 calc(25% - 10px);
  }

  .image:nth-child(2) {
    margin-top: 1%;
  }

  .image:nth-child(3) {
    margin-top: 2%;
  }

  .image:nth-child(4) {
    margin-top: 3%;
  }

  .image:nth-child(5) {
    margin-top: 4%;
  }
}

@media (max-width: 600px) {

  .image:nth-child(2) {
    margin-top: 0;
  }

  .image:nth-child(3) {
    margin-top: 0;
  }

  .image:nth-child(4) {
    margin-top: 0;
  }

  .image:nth-child(5) {
    margin-top: 0;
  }

  .image:nth-child(6) {
    margin-top: 0;
  }
}

@media (max-width: 400px) {
  .image:nth-child(2) {
    margin-top: 0;
  }

  .image:nth-child(3) {
    margin-top: 0;
  }

  .image:nth-child(4) {
    margin-top: 0;
  }

  .image:nth-child(5) {
    margin-top: 0;
  }

  .image:nth-child(6) {
    margin-top: 0;
  }
}