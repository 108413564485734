.body {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0;
  justify-content: center;
  max-width: 100%;
}

.item {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.item:hover .image {
  transform: scale(1.2);
}

.textOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
}

.overlayTitle {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.overlayDescription {
  font-size: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.item:hover .overlayDescription {
  opacity: 1;
}


@media (max-width: 768px) {
  .body {
    grid-template-columns: repeat(2, 1fr);
    /* 모바일에서는 2개씩 */
  }
}


/* Menu.module.css--------------------------- */
.menu {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  width: 60%;
  margin: 0 auto;
}

.menuCategory {
  background-color: #fff;
  padding: 20px;
}

.categoryTitle {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

.menuTable {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  display: table;
  table-layout: fixed;
  /* 고정된 테이블 크기 */
}

.menuTable thead {
  background-color: #B3907A;
  color: #fff;
}

.menuTable th {
  padding: 12px 15px;
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  border-bottom: none;
}

.menuTable tbody tr {
  background-color: #fff;
}

.menuTable td {
  padding: 10px 15px;
  font-size: 14px;
  color: #333;
}

.itemName {
  font-weight: bold;
}

.itemHot,
.itemIce {
  color: #e74c3c;
  font-size: 16px;
}

.menuTable tbody tr:last-child {
  border-bottom: none;
}


@media screen and (max-width: 768px) {
  .menu {
    width: 90%;
    padding: 15px;
  }

  .menuCategory {
    padding: 15px;
  }

  .categoryTitle {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .menuTable {
    width: 100%;
    table-layout: auto;
    font-size: 12px;
  }

  .menuTable th {
    padding: 10px 12px;
    font-size: 14px;
  }

  .menuTable td {
    padding: 8px 10px;
  }


  .itemName {
    font-size: 14px;
  }

  .itemHot,
  .itemIce {
    font-size: 14px;
  }


  .menuTable {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    /* iOS에서 자연스러운 스크롤 */
  }
}